<template>
  <component v-bind:is="layout()"></component>
</template>

<script>
// レイアウトコンポーネントをインポート
import DefaultLayout from './components/layout/DefaultLayout.vue'
import LoginLayout from './components/layout/LoginLayout.vue'

export default {
  components: {
    'default-layout': DefaultLayout,
    'login-layout': LoginLayout
  },
  methods: {
    layout () {
    // router.jsのroutesにmeta.layoutの存在確認
    // セットするレイアウトを判断する
      let layout = this.$route.meta.layout ? this.$route.meta.layout + '-layout' : 'default-layout'
      return layout
    }
  }
}
</script>
