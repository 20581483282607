<template>
  <div>
    <v-app>
      <v-navigation-drawer class="no-print" app v-model="drawer" :width="navigation.width" ref="drawer">
        <v-list-item to='/'>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="title">新岡MES v1.1.0</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list nav>
          <v-list-item 
              v-for="menu in menus" 
              :key="menu.title" 
              :to="menu.url"
              v-show="loginFunctionIdList.includes(menu.functionid)" 
          >
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        <v-list-group 
            v-for="menu in groupMenus" 
            :key="menu.title" 
            :prepend-icon="menu.icon"
            v-show="loginFunctionIdList.includes(menu.functionid)"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </template>
            <template v-for="menu in menu.subMenu">
              <!-- ネストしている構造 -->
              <v-list-group 
                  v-if="menu.subMenu"
                  :key="menu.title" 
                  v-show="loginFunctionIdList.includes(menu.functionid)"
                  sub-group
              >
                <template v-slot:activator>
                  <v-list-item-icon class="mr-5">
                    <v-icon>{{ menu.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ menu.title }}</v-list-item-title>
                </template>
                <v-list-item
                    v-for="menu in menu.subMenu" :key="menu.title" :to="menu.url"
                    v-show="loginFunctionIdList.includes(menu.functionid)"
                    link
                >
                  <v-list-item-icon class="ml-10">
                    <v-icon>{{ menu.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ menu.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <!-- ネストしていない構造 -->
              <v-list-item
                  v-else
                  :key="menu.title" :to="menu.url"
                  v-show="loginFunctionIdList.includes(menu.functionid)"
                  link
              >
                <v-list-item-icon class="ml-5">
                  <v-icon>{{ menu.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ menu.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
        </v-list-group>
        <v-list-item
            @click="goToWMS"
            v-show="loginFunctionIdList.some(item => item.includes('W_'))"
            link
        >
          <v-list-item-icon>
            <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>WMSへ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar class="no-print" app dense :style="color">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <account-menu />
      </v-app-bar>
      <v-main>
        <router-view />
      </v-main>
      <v-footer app></v-footer>
    </v-app>
  </div>
</template>

<script>
import loading from "vue-loading-overlay";
import AccountMenu from '@/components/AccountMenu.vue';
export default {
  name: "MenuList",
  props: {
    menus: {
      type: Array,
      required: true,
    },
    groupMenus: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    navigation: {
      width: 320,
      borderSize: 3
    },
    drawer: false,
    color: process.env.VUE_APP_BG_COLOR,
    url: process.env.VUE_APP_MES_URL,
    loginFunctionIdList: [],
    settingMenuWidthKey: null,
  }),
  components: {
    AccountMenu
    // confirmModal
  },
  mounted() {
    var loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
    // 未ログインチェック
    if(loginInfo === null){
      this.$router.push('/login');
      return;
    }
    // 有効期限チェック
    var limitDate = new Date(loginInfo.limit);
    var now = new Date();
    if(limitDate < now){
      localStorage.setItem("loginInfo", null);
      this.$router.push('/login');
      return;
    } else {
      // 6時間後に更新
      var newLimitDate = new Date();
      newLimitDate.setHours(newLimitDate.getHours() + 6);
      loginInfo.limit = newLimitDate;
      const loginInfoJSONData = JSON.stringify(loginInfo);
      localStorage.setItem("loginInfo", loginInfoJSONData);
    }

    this.loginFunctionIdList = loginInfo.functionid;

    // メニュー幅をローカルストレージから復元
    this.settingMenuWidthKey = 'settingsMenuWidth-' + loginInfo.accountid;
    const settingWidth = localStorage.getItem(this.settingMenuWidthKey);
    if (settingWidth != null) {
      this.navigation.width = settingWidth;
    }

    this.setBorderWidth();
    this.setEvents();
  },
  methods: {
    setBorderWidth() {
      let i = this.$refs.drawer.$el.querySelector(".v-navigation-drawer__border");
      i.style.width = this.navigation.borderSize + "px";
      i.style.cursor = "ew-resize";
    },
    setEvents() {
      // @see https://stackoverflow.com/questions/55261712/vuetify-navigation-drawer-drag-to-resize
      const minSize = this.navigation.borderSize;
      const el = this.$refs.drawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      // const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right") ? "right" : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f =
          direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        
        // メニューの最小幅、最大幅を制御
        const minMenuWidth = 200;
        const maxMenuWidth = 400;
        if (f < minMenuWidth ) { f = minMenuWidth }
        else if (f > maxMenuWidth ) { f = maxMenuWidth }
        el.style.width = f + "px";
      }

      drawerBorder.addEventListener(
        "mousedown",
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = "initial";
            // リサイズ開始時は文字選択状態を解除
            if (window.getSelection) {
              window.getSelection().removeAllRanges();
            }
            document.addEventListener("mousemove", resize, false);
          }
        },
        false
      );

      document.addEventListener(
        "mouseup",
        () => {
          el.style.transition = "";
          this.navigation.width = el.style.width;
          document.body.style.cursor = "";
          
          // リサイズ終了時の値をローカルストレージに永続化
          const settingWidth = localStorage.setItem(this.settingMenuWidthKey, el.style.width);
          document.removeEventListener("mousemove", resize, false);
        },
        false
      );
    },
    goToWMS() {
      window.location.href = '/wms/';
    },
  },
};
</script>
<style lang="scss">
@media screen {
  .a4-width {
    // width: 210mm;
    margin: 5mm auto 5mm auto;
  }

  .no-disp {
    display: none !important;
  }

  .print-table {
    width:100%;
    border-top: thin solid black !important;
    border-left: thin solid black !important;
    border-radius: 0px;

    td {
      text-align: center;
      font-weight: bold;
      font-size: 5vw;
      padding: 10px !important;
      border-right: thin solid black !important;
      border-bottom: thin solid black !important;
      
    }
    th {
      font-weight: bold;
      font-size: 5vw;
      padding: 10px !important;
      border-right: thin solid black !important;
      border-bottom: thin solid black !important;
    }
  }
}

@media print {
  main {
    padding: 0 !important;
  }

  .no-print {
    display: none !important;
  }

}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
</style>